import React, { useState } from "react";

const HowItWork = () => {
  const [openTab, setOpenTab] = useState(4);
  return (
    <>
      <section id="offer-care" className="howit-worksection pt-120 pb-120">
        <div className="container">
          <div className="latest-project-wrap1 position-relative pb-120">
            <div className="row justify-content-between align-items-center mb-60 g-3">
              <div className="col-lg-6 col-md-6">
                <div className="cmn-section-title apointment-content">
                  <a
                    href="#offer-care"
                    className="cmn--btn cmn-alt2 wow fadeInUp"
                    data-wow-delay="0.4"
                  >
                    <span>Our Latest Project</span>
                  </a>
                  <h2
                    className="title mt-xxl-4 mt-2 mb-0 wow fadeInDown"
                    data-wow-delay="0.5"
                  >
                    View Our Latest Project In This Month We Made
                  </h2>
                </div>
              </div>
              <div
                className="col-xl-5 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="0.6"
              >
                <p className="pra">
                  When hiring a cleaning service, consider factors such as their
                  reputation, experience, pricing, and whether they provide
                  eco-friendly or green cleaning options.
                </p>
              </div>
            </div>
            <div className="options">
              <div
                onClick={() => setOpenTab(1)}
                className={`option ${openTab === 1 ? "active" : ""}`}
              >
                <div className="shadow"></div>
                <div className="label">
                  <div className="icon-custom">
                    <h3 className="title">Office Cleaning</h3>
                  </div>
                  <div className="info">
                    <div className="cont">
                      <div className="main headfont">Commercial Cleaning</div>
                      <div className="sub prafont">Office, Clean</div>
                    </div>
                    <i className="bi bi-arrow-up-right"></i>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setOpenTab(2)}
                className={`option ${openTab === 2 ? "active" : ""} click2`}
              >
                <div className="shadow"></div>
                <div className="label">
                  <div className="icon-custom">
                    <h3 className="title">Hospital & School Cleaning</h3>
                  </div>
                  <div className="info">
                    <div className="cont">
                      <div className="main headfont">
                        Hospital & School Cleaning
                      </div>
                      <div className="sub prafont">Hospital & School</div>
                    </div>
                    <i className="bi bi-arrow-up-right"></i>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setOpenTab(3)}
                className={`option ${openTab === 3 ? "active" : ""} click3`}
              >
                <div className="shadow"></div>
                <div className="label">
                  <div className="icon-custom">
                    <h3 className="title">Hourly Cleaning</h3>
                  </div>
                  <div className="info">
                    <div className="cont">
                      <div className="main headfont">Hourly Cleaning</div>
                      <div className="sub prafont">Hourly, Clean</div>
                    </div>
                    <i className="bi bi-arrow-up-right"></i>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setOpenTab(4)}
                className={`option ${openTab === 4 ? "active" : ""} click1`}
              >
                <div className="shadow"></div>
                <div className="label">
                  <div className="icon-custom">
                    <h3 className="title">Club Cleaning</h3>
                  </div>
                  <div className="info">
                    <div className="cont">
                      <div className="main headfont">Club Cleaning</div>
                      <div className="sub prafont">Club, Clean</div>
                    </div>
                    <i className="bi bi-arrow-up-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="work-wrapper1">
            <img
              src={require("../assets/images/icon/flower.png")}
              alt="img"
              className="follower"
            />
            <div className="row justify-content-lg-between justify-content-center">
              <div className="col-lg-7">
                <div className="work-thumb">
                  <img
                    src={require("../assets/images/about/process.jpg")}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-7">
                <div className="howwork-content">
                  <div className="cmn-section-title">
                    <a
                      href="#offer-care"
                      className="cmn--btn cmn-alt1 wow fadeInDown"
                      data-wow-delay="0.4"
                    >
                      <span>Working Process</span>
                    </a>
                    <h2
                      className="white mt-xxl-4 mt-2 wow fadeInUp"
                      data-wow-delay="0.5"
                    >
                      How It Works
                    </h2>
                    <p
                      className="text-white wow fadeInDown"
                      data-wow-delay="0.6"
                    >
                      Our cleaning service follows a simple process: assess,
                      clean, sanitize, and inspect for impeccable results every
                      time.
                    </p>
                  </div>
                  <div className="howit-work-info">
                    <div
                      className="howit-item wow fadeInUp"
                      data-wow-delay="0.7"
                    >
                      <h3 className="title d-flex align-items-center gap-2">
                        <span className="base">01.</span>
                        Assessment and Planning
                      </h3>
                      <p className="pra">
                        We prioritize understanding our client's needs and
                        expectations through consultations to tailor our
                        services accordingly.
                      </p>
                    </div>
                    <div
                      className="howit-item wow fadeInDown"
                      data-wow-delay="0.8"
                    >
                      <h3 className="title d-flex align-items-center gap-2">
                        <span className="base">02.</span>
                        Execution of Cleaning Services
                      </h3>
                      <p className="pra">
                        We meticulously execute cleaning tasks, ensuring every
                        detail aligns with our client's specifications and
                        preferences.
                      </p>
                    </div>
                    <div
                      className="howit-item wow fadeInUp"
                      data-wow-delay="0.9"
                    >
                      <h3 className="title d-flex align-items-center gap-2">
                        <span className="base">03.</span>
                        Post-Cleaning Follow-up
                      </h3>
                      <p className="pra">
                        Our commitment extends beyond the service as we conduct
                        thorough follow-ups to ensure client satisfaction and
                        address any further needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src={require("../assets/images/icon/working-ball.png")}
              alt="img"
              className="working-bable"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWork;
