import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer__section position-relative">
        <div className="container">
          {/* <div className="newsletter-section">
                        <h3 className="title subcribe-title wow fadeInDown" data-wow-delay="0.4s">
                            Subscribe to Our Newsletter
                        </h3>
                        <form action="#">
                            <span className="fz-16 title d-block fw-700 prafont">
                                Enter your email
                            </span>
                            <input type="text" />
                            <button type="button" className="cmn--btn">
                                <span>
                                    Explore Our Offers
                                </span>
                            </button>
                        </form>
                    </div> */}
          <div className="footer__top pb-120">
            <div className="row g-4 justify-content-md-center">
              <div
                className="col-xl-4 col-md-5 col-sm-6 wow fadeInUp"
                data-wow-duration="2.1s"
              >
                <div className="footer__item">
                  <a href="#home" className="footer-logo">
                    <img
                      src={require("../assets/images/logo/Frame 1261156879.png")}
                      alt="img"
                    />
                  </a>
                  <p className="prag">
                    Passionate about Precision. With every clean, we're fueled
                    by a commitment to excellence. Trust us to infuse dedication
                    and expertise into every task, delivering spotless spaces
                    that inspire and uplift.
                  </p>
                </div>
              </div>
              <div
                className="col-xl-2 col-md-3 col-sm-6 wow fadeInUp"
                data-wow-duration="2.3s"
              >
                <div className="footer__item">
                  <h4 className="footer__title">Quick Links</h4>
                  <ul className="clink">
                    <li>
                      <a href="#home">Home</a>
                    </li>
                    <li>
                      <a href="#about">About Us</a>
                    </li>
                    <li>
                      <a href="#services">Services</a>
                    </li>
                    <li>
                      <a href="#quote">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-xl-2 col-md-4 col-sm-6 wow fadeInUp"
                data-wow-duration="2.2s"
              >
                <div className="footer__item">
                  <h5 className="footer__title">Our Contact</h5>
                  <ul className="get__touch">
                    <li>
                      <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.84091 12.25L0.5 14.875V1C0.5 0.58579 0.83579 0.25 1.25 0.25H14.75C15.1642 0.25 15.5 0.58579 15.5 1V11.5C15.5 11.9142 15.1642 12.25 14.75 12.25H3.84091ZM3.32211 10.75H14V1.75H2V11.7888L3.32211 10.75ZM7.25 5.5H8.75V7H7.25V5.5ZM4.25 5.5H5.75V7H4.25V5.5ZM10.25 5.5H11.75V7H10.25V5.5Z"
                          fill="#2295FF"
                        />
                      </svg>
                      <a
                        style={{ textTransform: "none" }}
                        href="mailto:info@45cleaners.com.au"
                      >
                        info@45cleaners.com.au
                      </a>
                    </li>
                    <li>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.02417 6.01158C5.7265 7.2466 6.7534 8.2735 7.98842 8.9758L8.6518 8.04708C8.87238 7.73838 9.2887 7.64297 9.6217 7.82485C10.6768 8.40123 11.8428 8.75148 13.0592 8.84778C13.4492 8.87868 13.75 9.20417 13.75 9.59545V12.9426C13.75 13.3271 13.4591 13.6493 13.0766 13.6886C12.6792 13.7295 12.2783 13.75 11.875 13.75C5.45469 13.75 0.25 8.5453 0.25 2.125C0.25 1.7217 0.270565 1.32078 0.311418 0.92332C0.350725 0.540812 0.672955 0.25 1.05749 0.25H4.40456C4.79583 0.25 5.12135 0.55078 5.15222 0.940817C5.2485 2.15716 5.59877 3.32323 6.17515 4.37833C6.35703 4.7113 6.26162 5.12766 5.95292 5.34818L5.02417 6.01158ZM3.13319 5.5189L4.55815 4.50107C4.1541 3.62885 3.87721 2.70387 3.73545 1.75H1.7568C1.75227 1.87474 1.75 1.99975 1.75 2.125C1.75 7.71685 6.28315 12.25 11.875 12.25C12.0002 12.25 12.1253 12.2478 12.25 12.2432V10.2645C11.2962 10.1228 10.3712 9.84587 9.49892 9.44185L8.4811 10.8668C8.06935 10.7069 7.6717 10.5186 7.29055 10.3046L7.24697 10.2797C5.77728 9.44402 4.55601 8.22272 3.72025 6.75303L3.69545 6.70945C3.48137 6.3283 3.29316 5.93065 3.13319 5.5189Z"
                          fill="#2295FF"
                        />
                      </svg>
                      <a href="tel:+487432602396">+61 466091231</a>
                    </li>
                    <li>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 0.75V2.25H10.25V0.75H11.75V2.25H14.75C15.1642 2.25 15.5 2.58579 15.5 3V15C15.5 15.4142 15.1642 15.75 14.75 15.75H1.25C0.83579 15.75 0.5 15.4142 0.5 15V3C0.5 2.58579 0.83579 2.25 1.25 2.25H4.25V0.75H5.75ZM14 8.25H2V14.25H14V8.25ZM4.25 3.75H2V6.75H14V3.75H11.75V5.25H10.25V3.75H5.75V5.25H4.25V3.75Z"
                          fill="#2295FF"
                        />
                      </svg>
                      <span>Mon - Fri 12:00 - 18:00</span>
                    </li>
                    <li>
                      <svg
                        className="footer-icons"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 14.6746L10.7123 10.9623C12.7625 8.91208 12.7625 5.58794 10.7123 3.53769C8.66208 1.48744 5.33794 1.48744 3.28769 3.53769C1.23744 5.58794 1.23744 8.91208 3.28769 10.9623L7 14.6746ZM7 16.7959L2.22703 12.023C-0.40901 9.3869 -0.40901 5.11307 2.22703 2.47703C4.86307 -0.15901 9.1369 -0.15901 11.773 2.47703C14.409 5.11307 14.409 9.3869 11.773 12.023L7 16.7959ZM7 8.75C7.82845 8.75 8.5 8.07845 8.5 7.25C8.5 6.42157 7.82845 5.75 7 5.75C6.17155 5.75 5.5 6.42157 5.5 7.25C5.5 8.07845 6.17155 8.75 7 8.75ZM7 10.25C5.34314 10.25 4 8.90683 4 7.25C4 5.59314 5.34314 4.25 7 4.25C8.65683 4.25 10 5.59314 10 7.25C10 8.90683 8.65683 10.25 7 10.25Z"
                          fill="#2295FF"
                        />
                      </svg>
                      <span>
                        308/440 Hamilton Rd Chermside QLD 4032 Australia
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <p>Copyright &copy; 45cleaners All Rights Reserved.</p>
        </div>
        <img
          src={require("../assets/images/footer/footer-spra.png")}
          alt="img"
          className="footer-spara"
        />
        <img
          src={require("../assets/images/icon/working-ball.png")}
          alt="img"
          className="footer-working"
        />
      </footer>
    </>
  );
};

export default Footer;
