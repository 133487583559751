import React, { useEffect } from "react";
import "../assets/css/swiper.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Provide = () => {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 3500,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section
      id="services"
      className="we-provide pt-120 pb-120 section-bg overflow-hidden"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="cmn-section-title text-center apointment-content mb-60">
              <a
                href="#services"
                className="cmn--btn cmn-alt2 mb-xxl-4 mb-3 wow fadeInDown"
                data-wow-delay="0.6s"
              >
                <span>What We Provide</span>
              </a>
              <h2 className="title mb-0 wow fadeInDown" data-wow-delay="0.8s">
                What We Do for Customer
              </h2>
            </div>
          </div>
        </div>
      </div>

      <Slider {...settings}>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/w4.png")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/house.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">Bond Cleaning</a>
              </h3>
              <p>
                Restore your space to perfection with our meticulous bond
                cleaning process, leaving no corner untouched.
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/w5.png")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/leaner.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">End of Lease Cleaning</a>
              </h3>
              <p>
                Elevate your moving experience with our comprehensive End of
                Lease Cleaning service, ensuring a seamless transition for your
                next chapter.
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/spring-house.jpg")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/house.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">Spring House Cleaning</a>
              </h3>
              <p>
                Embrace the freshness of a new season with our thorough Spring
                House Cleaning service, bringing renewed vitality and clarity to
                your home.
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/w2.png")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/wicon1.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">Hourly Cleaning</a>
              </h3>
              <p>
                Experience the convenience and flexibility of our Hourly
                Cleaning service, tailored to fit your schedule and needs, one
                hour at a time.
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/carpet.jpg")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/house.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">Carpet Cleaning</a>
              </h3>
              <p>
                Regular carpet cleaning enhances appearance, prolongs carpet
                lifespan, and promotes a healthier indoor environment by
                removing trapped allergens and bacteria.
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/office.jpg")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/house.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">Office & Commercial Cleaning</a>
              </h3>
              <p>
                Elevate your workspace standards with our meticulous Office &
                Commercial Cleaning service, ensuring a pristine environment
                that reflects your professionalism.
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/sofa.jpg")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/leaner.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">
                  Couches, Recliner, Upholstery, Rug, Car seat and Sofas
                </a>
              </h3>
              <p>
                Revitalize your favorite furnishings with our expert Upholstery
                and Rug Cleaning service, extending the life and beauty of your
                couches, recliners, car seats, and sofas.
              </p>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="we-provide-item">
            <div className="thumb">
              <img
                src={require("../assets/images/service/pest.jpg")}
                alt="img"
                className="mimg"
              />
              <div className="we-cion">
                <img
                  src={require("../assets/images/icon/wicon1.png")}
                  alt="img"
                />
              </div>
            </div>
            <div className="content">
              <h3>
                <a href="#quote">Pest Control</a>
              </h3>
              <p>
                Eliminate pests and safeguard your space with our comprehensive
                Pest Control service, ensuring peace of mind for your home or
                business.
              </p>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Provide;
