import { Button, Form, Input, Select, message } from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import React, { useRef, useState } from "react";
const REACT_APP_SOCKET = process.env.REACT_APP_SOCKET;

const CustomMessage = (type, content) => {
  // console.log(first)
  const getMessageClass = (messageType) => {
    return messageType === "success" ? "success-message" : "error-message";
  };

  message[type]({
    content: <span className={getMessageClass(type)}>{content}</span>,
  });
};

const CleaningQuote = () => {
  const [form] = Form.useForm();
  const formRef = useRef();
  const dummyEmptyObj = {
    name: "",
    email: "",
    contactNo: "",
    typeofCleanig: [],
    description: "",
  };
  const [submitObject, setsubmitObject] = useState(dummyEmptyObj);
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setsubmitObject(values);
    setLoading(true);
    const payload = {
      name: values.name,
      email: values.email,
      contactNo: values.contactNo,
      typeofCleanig: values.typeofCleanig,
      description: values.description ?? "",
    };
    axios
      .post(`${REACT_APP_SOCKET}/clean/cleaningData_post`, payload)
      .then((res) => {
        if (res.data.success) {
          CustomMessage("success", res.data.message);
          setLoading(false);
          setsubmitObject(dummyEmptyObj);
          form.resetFields();
        } else {
          CustomMessage("error", res.data.message);
          setLoading(false);
          setsubmitObject(dummyEmptyObj);
        }
      });
  };

  return (
    <>
      <section id="quote" className="cleaning-quote position-relative">
        <div className="cleaning-thumbing">
          <img
            src={require("../assets/images/service/cleaning-thumb.png")}
            alt="img"
          />
        </div>
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-5">
              <div className="cleaning-quote-content">
                <div className="cmn-section-title">
                  <a
                    href="#quote"
                    className="cmn--btn cmn-alt2 wow fadeInDown"
                    data-wow-delay="0.4s"
                  >
                    <span>Contact Us</span>
                  </a>
                  <h2
                    className="white mt-xxl-4 mt-2 mb-0 wow fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    Request a free cleaning quote today
                  </h2>
                </div>
                <div className="cleaning-info">
                  <div
                    className="cleaning-info-item wow fadeInDown"
                    data-wow-delay="0.7s"
                  >
                    <div className="c-info-icon d-flex align-items-center">
                      <i className="bi bi-geo-alt-fill"></i>
                      <span className="pra">Location</span>
                    </div>
                    <h3 className="title">
                      308/440 Hamilton Rd Chermside
                      <span className="d-block">QLD 4032 Australia</span>
                    </h3>
                  </div>
                  <div
                    className="cleaning-info-item wow fadeInUp"
                    data-wow-delay="0.8s"
                  >
                    <div className="c-info-icon d-flex align-items-center">
                      <i className="bi bi-envelope-fill"></i>
                      <span className="pra">Mail Us</span>
                    </div>
                    <h3 className="title">
                      <a
                        style={{ textTransform: "none" }}
                        href="#0"
                        className="d-block title"
                      >
                        info@45cleaners.com.au
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <Form
                form={form}
                ref={formRef}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                className="form-custom"
              >
                <div className="row gy-1 gx-3">
                  <div className="col-sm-6 col-12">
                    <label htmlFor="name">
                      First Name <span className="text-danger">*</span>{" "}
                    </label>
                    <Form.Item
                      className="mt-2"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Name!",
                        },
                      ]}
                    >
                      <Input
                        value={submitObject.name}
                        type="name"
                        size="large"
                        id="name"
                        placeholder="Enter Name"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-sm-6 col-12">
                    <label htmlFor="email">
                      Email <span className="text-danger">*</span>
                    </label>
                    <Form.Item
                      className="mt-2"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Email!",
                        },
                      ]}
                    >
                      <Input
                        value={submitObject.email}
                        size="large"
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                      />
                    </Form.Item>
                  </div>

                  <div className="col-sm-6 col-12">
                    <label htmlFor="phone">
                      Contact No <span className="text-danger">*</span>
                    </label>
                    <Form.Item
                      className="mt-2"
                      name="contactNo"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Your Contact Number!",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="+61"
                        value={submitObject.contactNo}
                        type="tel"
                        id="phone"
                        name="contactNo"
                        placeholder="Enter Contact Number"
                        size="large"
                        onKeyPress={(e) => {
                          const pattern = /^[0-9\b]+$/;
                          if (!pattern.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-sm-6 col-12">
                    <label htmlFor="select">
                      Services <span className="text-danger">*</span>
                    </label>
                    <Form.Item
                      className="mt-2"
                      name="typeofCleanig"
                      rules={[
                        {
                          required: true,
                          message: "Please Select One or More Service!",
                          type: "array",
                        },
                      ]}
                    >
                      <Select
                        maxTagCount="responsive"
                        value={submitObject.typeofCleanig}
                        id="select"
                        size="large"
                        name="typeofCleanig"
                        mode="multiple"
                        placeholder="Select Services"
                      >
                        {/* <Option value="Bond Cleaning">Bond Cleaning</Option>
                        <Option value="End of Lease Cleaning">
                          End of Lease Cleaning
                        </Option>
                        <Option value="Spring House Cleaning">
                          Spring House Cleaning
                        </Option>
                        <Option value="Hourly Cleaning">Hourly Cleaning</Option> */}
                        <Option value="Carpet Steam Cleaning">
                          Carpet Steam Cleaning
                        </Option>
                        <Option value="Office & Commercial Cleaning">
                          Office & Commercial Cleaning
                        </Option>
                        <Option value="Couches, Recliner, Upholstery, Rug, Car seat and Sofas">
                          Couches, Recliner, Upholstery, Rug, Car seat and Sofas
                        </Option>
                        <Option value="Pest Control">Pest Control</Option>
                        {/* <Option value="Other Services">Other Services</Option> */}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-12">
                    <label htmlFor="description">Description</label>
                    <Form.Item className="mt-2" name="description">
                      <Input.TextArea
                        style={{ minHeight: "110px" }}
                        placeholder="Description"
                        id="description"
                        name="description"
                        value={submitObject.description}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <Button loading={loading} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <img
          src={require("../assets/images/icon/working-ball.png")}
          alt="img"
          className="cleaning-shape"
        />
        <img
          src={require("../assets/images/icon/cirs.png")}
          alt="img"
          className="working-cirs"
        />
      </section>
    </>
  );
};

export default CleaningQuote;
